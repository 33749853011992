import React from 'react';
import { List } from 'views/offer/components/List';
import { ListItem } from 'views/offer/components/ListItem';
import { Paragraph } from 'views/offer/components/Paragraph';
import { LINKS } from 'constants/links';
import { treatmentsHubs } from 'constants/treatment-hubs';
import parse from 'html-react-parser';

export const getOfferBreadcrumbsLinks = (pathname) => {
  const breadcrumbsLinks = Object.values(treatmentsHubs).find(
    ({ treatments }) => treatments.find(({ href }) => pathname.includes(href))
  );

  return breadcrumbsLinks.links || [LINKS.OFFER];
};

export const resolveNodeContent = (data) => {
  const { type, content } = data;
  if (type === 'node') {
    return content;
  }
  if (type === 'paragraph') {
    return <Paragraph>{parse(content)}</Paragraph>;
  }
  if (type === 'ul') {
    return (
      <List>
        {content.map((item, listIdx) => (
          <ListItem key={listIdx}>{parse(item)}</ListItem>
        ))}
      </List>
    );
  }
  return null;
};
