import { LINKS } from './links';

export const treatmentsHubs = {
  botox: {
    links: [LINKS.OFFER, LINKS.TREATMENTS_BOTOX],
    treatments: [
      {
        id: 'am-13',
        text: 'Usuwanie zmarszczek mimicznych',
        href: 'usuwanie-zmarszczek-mimicznych-bot',
      },
      {
        id: 'am-19',
        text: 'Leczenie bruksizmu',
        href: 'leczenie-bruksizmu',
      },
      {
        id: 'am-2',
        text: 'Leczenie nadpotliwości',
        href: 'leczenie-nadpotliwosci-bot',
      },
      {
        id: 'am-20',
        text: 'Korekta uśmiechu dziąsłowego (gummy smile)',
        href: 'gummy-smile-usmiech-dziaslowy',
      },
    ],
  },
  hialuronicAcid: {
    links: [LINKS.OFFER, LINKS.TREATMENTS_HIALURONIC_ACID],
    treatments: [
      {
        id: 'am-3',
        text: 'Wolumetria twarzy',
        href: 'wolumetria-twarzy',
      },
      {
        id: 'am-7',
        text: 'Powiększanie lub modelowanie ust kwasem hialuronowym',
        href: 'modelowanie-powiekszanie-ust',
      },
      {
        id: 'am-14',
        text: 'Wypełnianie zmarszczek kwasem hialuronowym',
        href: 'wypelnianie-zmarszczek-kwasem-hialuronowym',
      },
      {
        id: 'am-15',
        text: 'Wypełnianie doliny łez',
        href: 'wypelnianie-doliny-lez',
      },
      {
        id: 'am-8',
        text: 'Skinbooster - kwas hialuronowy',
        href: 'neauvia-explosion',
      },
    ],
  },
  tissueStimulator: {
    links: [LINKS.OFFER, LINKS.TREATMENTS_TISSUE_STIMULATOR],
    treatments: [
      {
        id: 'am-10',
        text: 'Polinukleotydy',
        href: 'polinukleotydy-pluryal',
      },
      {
        id: 'am-11',
        text: 'Molekuła Młodości H+L',
        href: 'biostymulator-tkankowy-profhilo',
      },
      {
        id: 'am-17',
        text: 'Aminokwasowa terapia zastępcza',
        href: 'jalupro',
      },
      {
        id: 'am-21',
        text: 'Biostymulator na bazie Rh kolagenu',
        href: 'karisma',
      },
      {
        id: 'am-9',
        text: 'Zabieg z kwasem polimlekowym - silnym stymulatorem skóry',
        href: 'kwas-polimlekowy-sculptra',
      },
    ],
  },
  mesotherapy: {
    links: [LINKS.OFFER, LINKS.TREATMENTS_MESOTHERAPY],
    treatments: [
      {
        id: 'am-5',
        text: 'Mezoterapia igłowa - kwas hialuronowy',
        href: 'mezoterapia-neauvia-hydro-deluxe',
      },
      {
        id: 'am-6',
        text: 'Mezoterapia skóry głowy - stymulacja wzrostu włosów',
        href: 'leczenie-lysienia',
      },
      {
        id: 'am-12',
        text: 'Mezoterapia aminokwasowa',
        href: 'sunekos',
      },
      {
        id: 'am-16',
        text: 'Mezoterapia mikroigłowa',
        href: 'mezoterapia-mikroiglowa',
      },
      {
        id: 'am-18',
        text: 'Mezoterapia igłowa skóry wokół oczu - kwas hialuronowy',
        href: 'electri',
      },
    ],
  },
};
